import { TextField, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import axios from 'axios';

const ContactForm = () => {

  const [score, setScore] = useState(undefined);

  const recaptcha = useRef<any>(null);

  const verifyCallback = (recaptchaToken: any) => {
    // Here you will get the final recaptchaToken!!!  
    axios.post('https://websitehandlers.azurewebsites.net/api/pkcaptcha', {
      token: recaptchaToken
    })
    .then((res) => {
      console.log(res.data);
      setScore(res.data.score)
    })
    .catch((err) => {}) 
   
  }

  const updateToken = () => {
    // you will get a new token in verifyCallback
    recaptcha.current.execute();
  }

    useEffect(() => {
      loadReCaptcha('6Le2eyYcAAAAADwvE33pAWxffTSI0SDAyx3Ivy-o');
    }, [])

    return (
        <>
        <ReCaptcha
            ref={recaptcha}
            sitekey="6Le2eyYcAAAAADwvE33pAWxffTSI0SDAyx3Ivy-o"
            action='updateToken'
            verifyCallback={verifyCallback}
        />
        <form action="https://websitehandlers.azurewebsites.net/api/pkwebsitehandler" method="POST">
        <input hidden={true} name="score" value={ score || "" } readOnly={true}/>
        <div style={{paddingTop: 15}}>
            <TextField label="Name" style={{width: '100%'}} name="name"></TextField> <br/>
        </div>
        <div style={{paddingTop: 15}}>
          <TextField label="Email" style={{width: '100%'}} name="email"></TextField> <br/>
        </div>
        <div style={{paddingTop: 15}}>
          <TextField label="Message" rows={5} style={{width: '100%'}} multiline name="message"></TextField> <br/>
        </div>
        <div style={{padding: 14}}>
        
            <Button type="submit" style={{float: 'right', backgroundColor: '#363432', color: 'white'}}>Submit</Button>
        </div>
    </form>
    </>
    )
};

export default ContactForm;