import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

const CardComponent = ({ title, subtitle, image}: {title: string, subtitle: string, image?: string}) => {

    return (<Card style={{margin: '10px', backgroundColor: '#363432', color: 'white', minHeight: 500}}>
        <CardMedia
            image={image}
            style={{height: '300px'}}
            />
        <CardContent>
            <Typography variant="h5" style={{paddingBottom: '10px', fontFamily: 'operetta', fontSize: 30}}>
                {title}
            </Typography>
            <Typography style={{fontFamily: 'degular'}}>
                {subtitle}
            </Typography>
        </CardContent>
    </Card>)
}

export default CardComponent;