import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col } from  'react-bootstrap';

const ContactBar = () => {
    return (
        <div style={{ marginTop: '0px', marginBottom: '10px', paddingTop: '15px', paddingBottom: '5px', fontSize: '25px', textAlign: 'center', backgroundColor: '#363432'}}>
            <Container>
                <Row style={{color: 'white'}}>
                    <Col sm={6}>
                     <h4 style={{ fontFamily: 'degular'}}>Call us:</h4>
                     <p style={{fontSize: 25}}>0115 944 0911</p>
                    </Col>
                    <Col sm={6}>
                     <h4 style={{ fontFamily: 'degular'}}>Email us:</h4>
                     <a href="mailto:sales@panelkraft.design" style={{ color: 'white', fontSize: 25,  }}>sales@panelkraft.design</a>
                    </Col>
                </Row>
            </Container>
        </div>
        )
}

export default ContactBar;