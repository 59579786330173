import React from "react"
import { Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactBar from '../components/ContactBar';
import image from '../assets/images/img-2.jpg';
import Card from '../components/Card/Card';
import milton from '../assets/images/Milton_2.jpg';
import ukflag from '../assets/images/18166.jpg';
import About from '../components/About/About';
import Footer from '../components/Footer/Footer';
import Bespoke from '../components/Bespoke/Bespoke';
import Contact from '../components/Contact/Contact';

library.add(fal)

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero/>
    <ContactBar/>

    <Container fluid>
      <Row>
        <Col md={6} sm={12} style={{padding: '60px', paddingTop: 50}} className="my-auto">
          <h1 style={{textAlign: 'right', fontFamily: 'degular', fontSize: 37}}>Panelkraft have over 25 years experience in producing high quality kitchen, bedroom and bespoke furniture solutions.</h1>
        </Col>
        <Col md={6} sm={12} style={{paddingRight: '60px'}} className="my-auto">
          <h2>About Us</h2>
          <p style={{textAlign: 'justify'}} className="my-auto">
          Either from our standard range or to your own bespoke design, using the finest materials and highest specification hinge and drawer technology, we pride ourselves on the quality of the products we create. Having accounts with all the leading door manufacturers allows us to create the perfect furniture for anywhere in your home, office or retail premises.</p>
        </Col>
      </Row>
      <Row style={{padding: '50px', paddingTop: 10}}>
        <Col md={4}>
        <Card title="We're Open!" image={image} subtitle="Although closed to visitors due to the current restrictions, the factory remains operational and we’re available for enquires and quotations via phone and email."/>
        </Col>
        <Col md={4}>
          <Card image={milton} title="Highest Quality Materials" subtitle="From our doors to our hinges, we pride ourselves on using the highest quality materials."/>
        </Col>
        <Col md={4}>
          <Card image={ukflag} title="Made in the U.K" subtitle="We have been manufacturing kitchens and bespoke furniture
          from our Derbyshire based factory for over 25 years."/>
        </Col>
      </Row>

      <About/>
      <Bespoke/>

      <Contact/>
      
    </Container>

    <Footer/>

   
  </Layout>
)

export default IndexPage
