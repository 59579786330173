import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import styles from './Hero.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/images/600ppi/logo2020white.png';

const Hero = (props: any) => {
    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "images/Inzo_Front_H.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
            }
        }
    `)

    const backgroundFluidImageStack = [
        data.background.childImageSharp.fluid,
        `linear-gradient(0deg, rgba(13,13,14,0.6460959383753502) 9%, rgba(14,21,23,0) 100%)`,
      ].reverse()

    return  <BackgroundImage
    Tag="section"
    className={styles.header}
    fluid={backgroundFluidImageStack}
    backgroundColor={`#040e18`}
  >
    <Container className="text-center h-100 my-auto">
        <Row className="h-100 my-auto h-100">
            <Col className="h-100 my-auto h-100">
                <div className={`${styles.heroText} my-auto`}>
                    <img src={logo} className={styles.logo}/>
                    <h2 style={{ fontFamily: 'degular'}}>Bespoke Kitchens and Furniture manufactured in the U.K. </h2>
                </div>
            </Col>
        </Row>
    </Container>
  </BackgroundImage>
}

export default Hero;