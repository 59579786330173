import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from './ContactForm';

const Contact = () => {
    return (
        <Row style={{ padding: 100, paddingTop: 10}}>
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Contact Us</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        Contact us using the form below! <br/>

                        <ContactForm/>
                    
                    </Col>
                    <Col xs={12} sm={6} style={{textAlign: 'right', padding: 40}}>
                        <h3>Address</h3>
                        <p>16d Birkdale Close <br/>
                        Manners Industrial Estate <br/>
                        Ilkeston <br/>
                        Derbyshire <br/>
                        DE7 8YA </p>
                        <h3>Phone</h3>
                        0115 944 0911
                    </Col>
                </Row> 
            </Container>
        </Row>
    )
}

export default Contact;