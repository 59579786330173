import { Card, CardContent, CardMedia } from '@material-ui/core';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import image from '../../assets/images/bespoke.jpg';


const Bespoke = () => {

    return (
        <Row style={{marginBottom: '30px', padding: 60, paddingBottom: 0, paddingTop: 0}}>
            <Col xs={12} sm={6}>
                <Card>
                    <CardMedia image={image} style={{height: 400}}></CardMedia>
                </Card>
            </Col>
            <Col xs={12} sm={6}>
                    <div style={{padding: 40}}>
                    <h2>Bespoke</h2>
                    <p>We work to the exact specifications of our clients to create the most perfect solutions. As a business we pride ourselves on expert design and traditional skills. From luxury wardrobes to bespoke home offices, we create furniture that is unique to you.</p>
                    </div>
             
            </Col>
        </Row>
    )
}

export default Bespoke;