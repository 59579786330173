import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {

    return (<div style={{height: '100px', backgroundColor: '#363432', color: 'white'}}>
        <Container style={{paddingTop: '10px', textAlign: 'center'}}>
            <Row>
                <Col>
                    © {new Date().getFullYear()} Panelkraft Ltd
                </Col>
            </Row>
        </Container>
    </div>);
}

export default Footer;