import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardMedia, Typography, Button } from '@material-ui/core';
import { Container, Row, Col, } from 'react-bootstrap';
import image from '../../assets/images/Coleridge_painted.jpg';
import ArrowForwardIos from '@material-ui/icons/ChevronRight';
import { ArrowForward } from '@material-ui/icons';

const About = () => {
    return (
        <Row style={{padding: 60, paddingTop: 0}} className="h-100">
            <Col xs={12} sm={6} style={{textAlign: 'right'}} className="h-100">
                <div style={{padding: 40}}>
                <h2>Trade Kitchens</h2>
                <p>Panelkraft can help you work with the service you need to support your kitchen fitting or shopfront business. We don’t compromise on quality and our local base offers unparalleled service.</p>
                <p>Our wide product choice and availability will allow you to provide your clients with a truly bespoke kitchen – perfect for their needs. </p>
                {/* <Button style={{backgroundColor: '#363432', color: 'white' }}>LEARN MORE <ArrowForwardIos fontSize="small"/></Button> */}
                </div>
            </Col>
            <Col xs={12} sm={6}>
                <Card>
                    <CardMedia image={image} style={{ height: '400px'}}></CardMedia>
                </Card>
            </Col>
        </Row>
    )
}

export default About;